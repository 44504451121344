import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _ce45c48c = () => interopDefault(import('../_templates/pages/blog/Blog.vue' /* webpackChunkName: "" */))
const _6623f4ba = () => interopDefault(import('../_templates/pages/checkout/Checkout.vue' /* webpackChunkName: "" */))
const _f8a52fce = () => interopDefault(import('../_templates/pages/checkout/Checkout/Order.vue' /* webpackChunkName: "" */))
const _5f5862b8 = () => interopDefault(import('../_templates/pages/checkout/Checkout/Account.vue' /* webpackChunkName: "" */))
const _1f145eda = () => interopDefault(import('../_templates/pages/checkout/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _d94d7132 = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _3f3a69d5 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _06665f11 = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _299af5d6 = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _3557f261 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _f4a92ace = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _cebf4284 = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _8c88f430 = () => interopDefault(import('../_templates/pages/faq/Faq.vue' /* webpackChunkName: "" */))
const _4a9da39a = () => interopDefault(import('../_templates/pages/wishlist/Wishlist.vue' /* webpackChunkName: "" */))
const _3ee19c0c = () => interopDefault(import('../_templates/pages/quickorder/QuickOrder.vue' /* webpackChunkName: "" */))
const _7bcf023a = () => interopDefault(import('../_templates/pages/search/Search.vue' /* webpackChunkName: "" */))
const _10dd0610 = () => interopDefault(import('../_templates/pages/customer/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _473f2d0c = () => interopDefault(import('../_templates/pages/blog/BlogPost.vue' /* webpackChunkName: "" */))
const _543be13f = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollection.vue' /* webpackChunkName: "" */))
const _d5ef591c = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollectionItem.vue' /* webpackChunkName: "" */))
const _5abdb92c = () => interopDefault(import('../_templates/pages/theme/Home.vue' /* webpackChunkName: "" */))
const _22d6391a = () => interopDefault(import('../_templates/pages/theme/Page.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktuelt.html",
    component: _ce45c48c,
    name: "blog___b2b_nb_no"
  }, {
    path: "/checkout",
    component: _6623f4ba,
    name: "checkout___b2b_nb_no",
    children: [{
      path: "bestilling",
      component: _f8a52fce,
      name: "checkout-order___b2b_nb_no"
    }, {
      path: "konto",
      component: _5f5862b8,
      name: "checkout-account___b2b_nb_no"
    }, {
      path: "takk",
      component: _1f145eda,
      name: "checkout-thank-you___b2b_nb_no"
    }]
  }, {
    path: "/customer",
    component: _d94d7132,
    meta: {"titleLabel":"Account"},
    name: "customer___b2b_nb_no",
    children: [{
      path: "addresses-details",
      component: _3f3a69d5,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___b2b_nb_no"
    }, {
      path: "my-profile",
      component: _06665f11,
      meta: {"titleLabel":"My Account"},
      name: "customer-my-profile___b2b_nb_no"
    }, {
      path: "order-history",
      component: _299af5d6,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___b2b_nb_no"
    }, {
      path: "addresses-details/create",
      component: _3557f261,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___b2b_nb_no"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _f4a92ace,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___b2b_nb_no"
    }, {
      path: "order-history/:orderId",
      component: _cebf4284,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___b2b_nb_no"
    }]
  }, {
    path: "/faq.html",
    component: _8c88f430,
    name: "faq___b2b_nb_no"
  }, {
    path: "/onskeliste",
    component: _4a9da39a,
    name: "wishlist___b2b_nb_no"
  }, {
    path: "/quickorder",
    component: _3ee19c0c,
    name: "quickorder___b2b_nb_no"
  }, {
    path: "/search",
    component: _7bcf023a,
    name: "search___b2b_nb_no"
  }, {
    path: "/customer/reset-password",
    component: _10dd0610,
    alias: "/customer/account/createPassword",
    name: "reset-password___b2b_nb_no"
  }, {
    path: "/aktuelt/:slug+.html",
    component: _473f2d0c,
    name: "blogPost___b2b_nb_no"
  }, {
    path: "/collection/:slug([^/.]+).html",
    component: _543be13f,
    name: "attributeCollection___b2b_nb_no"
  }, {
    path: "/collection/:collection_slug/:slug+.html",
    component: _d5ef591c,
    name: "attributeCollectionItem___b2b_nb_no"
  }, {
    path: "/",
    component: _5abdb92c,
    name: "home___b2b_nb_no"
  }, {
    path: "/:slug+",
    component: _22d6391a,
    name: "page___b2b_nb_no"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
